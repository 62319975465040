import React from "react"
import { View, Flex, Heading, Text, Divider, Icon } from "@adobe/react-spectrum"
import Alert from "@spectrum-icons/workflow/Alert"

const FailureReason = ({ errorString }) => {
    return (
        <Flex
            direction="column"
            justifyContent="center"
            // alignItems="center"
            // height="100vh"
        >
            <View
                // width="size-4600"
               UNSAFE_style={{backgroundColor:"fef2f2"}}
                borderRadius="medium"
                boxShadow="medium"
            >
                <View
                    padding="size-200"
                    borderTopStartRadius="medium"
                    borderTopEndRadius="medium"
                    UNSAFE_style={{ backgroundColor: "#fef2f2", color: "#dc2625" }}
                >
                    <Flex alignItems="center" gap="size-100">
                        <Icon>
                            <Alert aria-label="Error" />
                        </Icon>
                        <Heading level={3} margin={0}>
                            Failure Reason
                        </Heading>
                    </Flex>
                </View>
                <View
                    padding="size-200"
                    paddingTop={0}
                    UNSAFE_style={{ backgroundColor: "#fef2f2" }}
                >
                    <Text
                        elementType="p"
                        UNSAFE_className="font-mono"
                        color="negative"
                        style={{
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-word",
                        }}
                    >
                        {errorString}
                    </Text>
                </View>
            </View>
        </Flex>
    )
}

export default FailureReason
