import React, { useEffect, useState } from "react"
import {
    Flex,
    View,
    Checkbox,
    CheckboxGroup,
    ComboBox,
    Text as SpectrumText,
    TimeField,
    NumberField,
    Item,
    Divider,
    ContextualHelp,
    Heading,
    Content,
} from "@adobe/react-spectrum"
import styles from "./AddAlert.module.css"
import DeleteButton from "./DeleteButton"
import AddButton from "./AddButton"
import { Time } from "@internationalized/date"
import { getTimezones } from "../utilities/api/admin-api"

const ScheduleParam = ({
    alertName,
    index,
    state,
    updateScheduleParamOption,
    addNewCustomSchedule,
    deleteCustomSchedule,
    handleDaysChange,
    handleAllDayChange,
    handleTimeChange,
    handleTimezoneChange,
    handleScheduleConditionChange,
    adminPermission,
    isEditable,
    operation,
}) => {
    // const standardTimeZones = [
    //     { name: "IST", value: "Indian Standard Time (IST)" },
    //     { name: "GMT", value: "Greenwich Mean Time (GMT)" },
    //     { name: "CST", value: "Central Standard Time (CST)" },
    //     { name: "PST", value: "Pacific Standard Time (PST)" },
    //     { name: "CET", value: "Central European Time (CET)" },
    //     { name: "JST", value: "Japan Standard Time (JST)" },
    // ]

    const [timezone, setTimezone] = useState()

    // Add an initial custom schedule if there are none when custom is selected
    useEffect(() => {
        if (state.custom && state.customSchedules.length === 0) {
            addNewCustomSchedule()
        }
    }, [state.custom])

    useEffect(() => {
        standardTimeZones()
    }, [])

    const standardTimeZones = () => {
        getTimezones("timezones")
            .then((resp) => {
                setTimezone(resp)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <View>
            <View padding={"0 0 0 3rem"}>
                <View>
                    <Checkbox
                        isSelected={state.everyday}
                        onChange={(isSelected) =>
                            updateScheduleParamOption("everyday", isSelected)
                        }
                        isDisabled={!isEditable && operation !== "add"}
                    >
                        <SpectrumText UNSAFE_className={styles.checkbox}>
                            Everyday
                        </SpectrumText>
                    </Checkbox>
                    <Checkbox
                        isSelected={state.custom}
                        onChange={(isSelected) =>
                            updateScheduleParamOption("custom", isSelected)
                        }
                        isDisabled={!isEditable && operation !== "add"}
                    >
                        <SpectrumText UNSAFE_className={styles.checkbox}>
                            Custom Schedule
                        </SpectrumText>
                    </Checkbox>
                </View>
            </View>
            {alertName !== "abandoned" &&
                alertName !== "voicemail" &&
                state.everyday && (
                    <View marginStart={"5rem"} marginY={"size-100"}>
                        <View>
                            <SpectrumText UNSAFE_className={styles.label}>
                                {alertName === "wait"
                                    ? "Wait Condition"
                                    : "Threshold"}
                            </SpectrumText>
                        </View>
                        <NumberField
                            width={"30rem"}
                            value={state.threshold}
                            onChange={(value) =>
                                updateScheduleParamOption("threshold", value)
                            }
                            minValue={0}
                            maxValue={86400}
                            defaultValue={0}
                            formatOptions={alertName === "wait" && {
                                style: "unit",
                                unit: "second",
                            }}
                            isDisabled={!isEditable && operation !== "add"}
                        />
                    </View>
                )}
            {state?.custom && (
                <>
                    <Flex marginStart={"5rem"}>
                        <View width={"66rem"}>
                            <SpectrumText
                                UNSAFE_className={styles.label}
                                marginTop={"8px"}
                            >
                                Custom Schedule
                            </SpectrumText>
                        </View>
                        <AddButton
                            title={"Schedule"}
                            onClick={addNewCustomSchedule}
                            // isDisabled={!isEditable}
                            operation={operation}
                            isEditable={isEditable}
                        />
                    </Flex>
                    {state?.customSchedules?.map((schedule, scheduleIndex) => (
                        <View
                            key={scheduleIndex}
                            marginStart={"2rem"}
                            marginY={"size-100"}
                        >
                            <View
                                padding={"1rem 0 1rem 3rem"}
                                UNSAFE_style={{
                                    padding: "1rem 0",
                                    borderRadius: "8px",
                                    border: "1px solid #C8C3C3",
                                    width: "72rem",
                                }}
                            >
                                <View>
                                    <Flex
                                        justifyContent={"space-between"}
                                        UNSAFE_style={{ width: "65rem" }}
                                    >
                                        <View>
                                            <SpectrumText
                                                UNSAFE_className={styles.label}
                                            >
                                                Days
                                            </SpectrumText>

                                            <CheckboxGroup
                                                marginStart={"15px"}
                                                orientation="horizontal"
                                                value={schedule.days.split(",")}
                                                onChange={(value) => {
                                                    handleDaysChange(
                                                        scheduleIndex,
                                                        value
                                                    )
                                                }}
                                                isDisabled={!isEditable && operation !== "add"}
                                            >
                                                {[
                                                    {
                                                        key: "MONDAY",
                                                        value: "Mon",
                                                    },
                                                    {
                                                        key: "TUESDAY",
                                                        value: "Tue",
                                                    },
                                                    {
                                                        key: "WEDNESDAY",
                                                        value: "Wed",
                                                    },
                                                    {
                                                        key: "THURSDAY",
                                                        value: "Thu",
                                                    },
                                                    {
                                                        key: "FRIDAY",
                                                        value: "Fri",
                                                    },
                                                    {
                                                        key: "SATURDAY",
                                                        value: "Sat",
                                                    },
                                                    {
                                                        key: "SUNDAY",
                                                        value: "Sun",
                                                    },
                                                ].map((item) => (
                                                    <Checkbox
                                                        key={item.key}
                                                        value={item.key}
                                                    >
                                                        {item.value}
                                                    </Checkbox>
                                                ))}
                                            </CheckboxGroup>
                                        </View>

                                        <DeleteButton
                                            onClick={() =>
                                                deleteCustomSchedule(
                                                    scheduleIndex
                                                )
                                            }
                                            isDisabled={
                                               ( !isEditable && operation !== "add") ||
                                                state.customSchedules.length ===
                                                    1
                                            }
                                        />
                                    </Flex>
                                </View>
                                <View>
                                    <Flex gap={"1rem"}>
                                        <Flex
                                            width={"30rem"}
                                            justifyContent={"space-between"}
                                        >
                                            <Flex>
                                                <Flex alignItems={"center"}>
                                                    <SpectrumText
                                                        UNSAFE_className={
                                                            styles.label
                                                        }
                                                    >
                                                        Time
                                                    </SpectrumText>
                                                </Flex>
                                                <Flex
                                                    alignItems={"center"}
                                                    marginStart={"15px"}
                                                >
                                                    <Checkbox
                                                        marginTop={"10px"}
                                                        isSelected={
                                                            schedule.startTime ===
                                                                "*" &&
                                                            schedule.endTime ===
                                                                "*"
                                                        }
                                                        onChange={() =>
                                                            handleAllDayChange(
                                                                scheduleIndex,
                                                                !(
                                                                    schedule.startTime ===
                                                                        "*" &&
                                                                    schedule.endTime ===
                                                                        "*"
                                                                )
                                                            )
                                                        }
                                                        isDisabled={
                                                            !isEditable && operation !== "add"
                                                        }
                                                    >
                                                        All Day
                                                    </Checkbox>
                                                    <View marginStart={"-10px"}>
                                                        <ContextualHelp variant="info">
                                                            <Heading>
                                                                All Day
                                                            </Heading>
                                                            <Content>
                                                                <SpectrumText>
                                                                    You will get
                                                                    the alert
                                                                    for the
                                                                    whole day.
                                                                </SpectrumText>
                                                            </Content>
                                                        </ContextualHelp>
                                                    </View>
                                                </Flex>
                                            </Flex>
                                            <Flex alignItems={"center"}>
                                                <View>
                                                    <Divider
                                                        height={"1px"}
                                                        width={"15px"}
                                                    />
                                                </View>
                                                <View marginX={"4px"}> or</View>
                                                <View>
                                                    <Divider
                                                        height={"1px"}
                                                        width={"15px"}
                                                    />
                                                </View>
                                            </Flex>
                                        </Flex>
                                        <Flex gap={"2rem"}>
                                            <View>
                                                <View>
                                                    <SpectrumText
                                                        UNSAFE_className={
                                                            styles.label
                                                        }
                                                    >
                                                        Start Time
                                                    </SpectrumText>
                                                </View>
                                                <TimeField
                                                    width={"14rem"}
                                                    hourCycle={24}
                                                    granularity="second"
                                                    value={
                                                        schedule.startTime !==
                                                            "*" &&
                                                        schedule.startTime
                                                            ? new Time(
                                                                  schedule.startTime.split(
                                                                      ":"
                                                                  )[0],
                                                                  schedule.startTime.split(
                                                                      ":"
                                                                  )[1],
                                                                  schedule.startTime.split(
                                                                      ":"
                                                                  )[2]
                                                              )
                                                            : new Time(0, 0, 0) // Default to 00:00:00
                                                    }
                                                    onChange={(value) =>
                                                        handleTimeChange(
                                                            scheduleIndex,
                                                            "startTime",
                                                            value
                                                        )
                                                    }
                                                    isDisabled={
                                                        (!isEditable && operation!=="add") ||
                                                        (schedule.startTime ===
                                                            "*" &&
                                                            schedule.endTime ===
                                                                "*")
                                                    }
                                                />
                                            </View>
                                            <View>
                                                <View>
                                                    <SpectrumText
                                                        UNSAFE_className={
                                                            styles.label
                                                        }
                                                    >
                                                        End Time
                                                    </SpectrumText>
                                                </View>
                                                <TimeField
                                                    width={"14rem"}
                                                    hourCycle={24}
                                                    granularity="second"
                                                    value={
                                                        schedule.endTime !==
                                                            "*" &&
                                                        schedule.endTime
                                                            ? new Time(
                                                                  schedule.endTime.split(
                                                                      ":"
                                                                  )[0],
                                                                  schedule.endTime.split(
                                                                      ":"
                                                                  )[1],
                                                                  schedule.endTime.split(
                                                                      ":"
                                                                  )[2]
                                                              )
                                                            : new Time(
                                                                  23,
                                                                  59,
                                                                  59
                                                              ) // Default to 00:00:00
                                                    }
                                                    onChange={(value) =>
                                                        handleTimeChange(
                                                            scheduleIndex,
                                                            "endTime",
                                                            value
                                                        )
                                                    }
                                                    isDisabled={
                                                        (!isEditable && operation !== "add" ) ||
                                                        (schedule.startTime ===
                                                            "*" &&
                                                            schedule.endTime ===
                                                                "*")
                                                    }
                                                />
                                            </View>
                                        </Flex>
                                    </Flex>
                                </View>

                                <View marginY={"10px"}>
                                    <Flex
                                        gap={"1rem"}
                                        UNSAFE_className={styles.placeholder}
                                    >
                                        <View>
                                            <View>
                                                <SpectrumText
                                                    UNSAFE_className={
                                                        styles.label
                                                    }
                                                >
                                                    Timezone
                                                </SpectrumText>
                                            </View>
                                            <ComboBox
                                                defaultItems={timezone || []}
                                                width={"30rem"}
                                                selectedKey={schedule.timezone}
                                                onSelectionChange={(value) =>
                                                    handleTimezoneChange(
                                                        scheduleIndex,
                                                        value
                                                    )
                                                }
                                                isDisabled={!isEditable && operation !== "add"}
                                            >
                                                {(item) => (
                                                    <Item key={item.abbr}>
                                                        {item.name}
                                                    </Item>
                                                )}
                                            </ComboBox>
                                        </View>
                                        {alertName !== "abandoned" &&
                                            alertName !== "voicemail" && (
                                                <View>
                                                    <View>
                                                        <SpectrumText
                                                            UNSAFE_className={
                                                                styles.label
                                                            }
                                                        >
                                                            {alertName ===
                                                            "wait"
                                                                ? "Wait Condition"
                                                                : "Threshold"}
                                                        </SpectrumText>
                                                    </View>
                                                    <NumberField
                                                        width={"30rem"}
                                                        value={
                                                            schedule?.threshold
                                                        }
                                                        onChange={(value) =>
                                                            handleScheduleConditionChange(
                                                                scheduleIndex,
                                                                value
                                                            )
                                                        }
                                                        minValue={0}
                                                        maxValue={86400}
                                                        defaultValue={0}
                                                        formatOptions={
                                                            alertName ===
                                                                "wait" && {
                                                                style: "unit",
                                                                unit: "second",
                                                            }
                                                        }
                                                        isDisabled={
                                                            !isEditable && operation !== "add"
                                                        }
                                                    />
                                                </View>
                                            )}
                                    </Flex>
                                </View>
                            </View>
                        </View>
                    ))}
                </>
            )}
        </View>
    )
}

export default ScheduleParam
