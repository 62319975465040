import { toast } from 'react-toastify';
export const CARE_USERNAME = 'user';
export const CARE_PASSWORD = 'T>kzIB5iNPrVt7GN';
export const ALERT_SERVICE_USERNAME = 'alert-service-user';
export const ALERT_SERVICE_PASSWORD = 'nUhwVuEn<ws=xHoF';
export const GDPR_USERNAME = 'admin';
export const GDPR_PASSWORD = 'admin';

export const SOCKET_URL = window.location.origin + '/logReadability';
export const GDPR_URL = window.location.origin + '/GDPR';
export const DOWNLOAD_REPORT = 'DOWNLOAD';
export const SEARCH_REPORT = 'SEARCH';
export const USER_INFO = 'USER_INFO';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SUPPORT_INDEX = 'SUPPORT_INDEX';
export const MAIN_INDEX = 'MAIN_INDEX';
export const SEARCH_PHONE = 'SEARCH_PHONE';
export const DELETE_PHONE = 'DELETE_PHONE';
export const SET_MENU = 'SET_MENU';
export const LAST_RUN = 'LAST_RUN';
export const BULK_RUN = 'BULK_RUN';
export const USER_AUTHORIZATION = 'USER_AUTHORIZATION';
export const USER_AUTHORIZATION_NEW = 'USER_AUTHORIZATION_NEW';
export const SPAM_CALLER_ADMIN = 'Spam Callers Admin';
export const ALERT_SERVICE_ADMIN = 'Alert Service Admin';
export const USER_ROLES_AND_PERMISSIONS = 'USER_ROLES_AND_PERMISSIONS'

// UpdatedPermissions
export const DUAL_SYSTEM_ADMIN = 'Dual System Admin';
export const SYSTEM_TEST_DATA_ADMIN = 'System Test Data Admin';
export const INTERACTION_REPORT_ADMIN = 'Interaction Report Attributes Data Admin';
export const SRS_PRODUCT_FAMILY_ADMIN = 'SRS Product Family Data Admin';
export const SRS_RULES = 'SRS Rules Admin';


// ActiveConnect Types
export const ACTIVE_CONNECTION = {
  connectionsTypes: {
    adobeConnect: 'amazon-connect',
    purecloud: 'purecloud'
  }
}


// SRS RULES COnSTANST
export const CONDITION_TYPE = {
  SIMPLE: "simple",
  COMPLEX: "complex",
}

export const ACTION_TYPE = {
  ALLOW: "allow",
  DENY: "deny",
  SUB_RULES: "sub_rules",
}

export const RULE_TYPE = {
  ROOT: "root",
  SUB: "sub",
}

export const RULE_STATUS = {
  ACTIVE: true,
  INACTIVE: false,
};

export const CASE_ROUTE = {
  PURECLOUD: "purecloud",
  AMAZON_CONNECT: "amazon-connect",
};

// StoreEventKey
export const ADOBE_IO_TOKEN = 'adobeIOToken';
export const AlertServiceToken = 'AlertServiceToken';

// Regex
//eslint-disable-next-line
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const URL_REGEX = `https://hooks.slack.com/services`;

export const SRS_NAME_regexPatternIN = /(.*?\..*?)\s(IN)\s(\[.*?\])/;
export const SRS_NAME_regexPatternNOTIN = /(.*?\..*?)\s(NOT-IN|NOT IN|NOT_IN)\s(\[.*?\])/;

export const UTIL_DASHBOARD = {
  search_host: {
    label: 'Search Host',
    defination: 'Utility for Single Host Search and Export Bulk Host Data',
  },
  agent_logout: {
    label: 'Agent Logout',
    defination:
      'Utility for Agent Logout with Place ID/LDAP for Vendor and Adobe Site',
  },
  capture_logs: { label: 'Capture Logs', defination: 'DN Level Log Capture' },
  email_alert: {
    label: 'Alert Management',
    defination: 'Utility for Turning Off/On Email Alerts',
  },
  prometheus_alert: {
    label: 'Prometheus Alerts',
    defination: 'Utility for Turning Off/On Prometheus Alerts For Jenkins',
  },
  export_list: {
    label: 'Export DNIS ',
    defination: 'Utility for bulk exporting DNIS mapping list',
  },
};

export const SELECT_TYPE = {
  stage: 'STAGE',
  prod: 'PROD',
};
export const SELECT_CENTRE_CODE = {
  32: 'Beijing',
  15: 'Gurgaon',
  33: 'Mansarover',
  19: 'Maastricht',
  37: 'COE_Dublin',
  5: 'COE_Edinburgh',
  35: 'Lehi',
  9: 'Adobe Noida',
  1: 'COE_Ottawa',
  99: 'COE_Remote',
  7: 'COE_Tokyo',
  36: 'Sapporo/bs24',
  96: 'COE_Seattle',
  91: 'COE_Basel',
  38: 'Lisbon',
  39: 'CNX Bangalore',
  40: 'CNX Korea',
};

export const SWITCH_TYPES = {
  101: 'HB_AGENT_SIP',
  102: 'SJ_AGENT_SIP',
  103: 'TP_EMEA_DEFF_SIP',
  104: 'TP_EMEA_AVAYA',
  105: 'TP_USA_AVAYA',
  106: 'Japan_Avaya',
  107: 'Japan_IVR',
  108: 'Japan_SIP',
  109: 'TP_EMEA_FRPAR_SIP',
  110: 'CVG_EMEA_AVAYA',
  111: 'CVG_EMEA_AM_SIP',
  112: 'CVG_EMEA_NC_SIP',
  114: 'Japan_SJ_SIP',
  115: 'CNX_USA_AVAYA',
};

export const SELECT_TEAM = {
  CONSUMER_ADOBE: 'Adobe Consumer',
  ENTERPRISE_ADOBE: 'Adobe Enterprise',
  CONSUMER_CNX: 'CNX Bangalore',
  CONSUMER_BS24: 'Japan BS24',
  CONSUMER_TPEMEA: 'TP_EMEA',
  CONSUMER_TPUSA: 'TP_USA',
  CONSUMER_ADOBE_TOKYO: 'Tokyo users',
  CONSUMER_CNX_KOREA: 'CNX Korea',
};
export const SELECT_USER_TYPE = {
  ldap: 'LDAP',
  placeId: 'PLACEID',
};

export const SELECT_REQUEST_TYPE = {
  access: 'Access Request',
  delete: 'Delete Request',
};

export const SELECT_SEARCH_TYPE = {
  ipaas_id: 'Ipass ID',
  guid: 'Guid',
  phone: 'Phone Number',
};

export const options = {
  lines: 13,
  length: 20,
  width: 10,
  radius: 30,
  scale: 1.0,
  corners: 1,
  color: '#ED1C24',
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 2e9,
  top: '300px',
  left: '50%',
  shadow: false,
  hwaccel: false,
};

export const config = {
  issuer: 'https://adobe.okta.com/oauth2/aus1gan31wnmCPyB60h8',
  redirectUri: window.location.origin + '/node-app/authenticate/login',
  postLogoutRedirectUri: window.location.origin + '/node-app',
  clientId: '0oa1nunsxhyu5BxD00h8',
  pkce: true,
};

export const schecudledActionSchemaMap = {
  COPY_OUTBOUND_CALLINGLIST: 'Outbound',
  PURGE_OUTBOUND: 'Outbound',
  PURGE_GIM_DATA: 'GIM',
  HASH_GDPR_REQUEST: 'GIM',
  PURGE_UCS_CONTACT: 'UCS',
  SEARCH_GDPR_ACCESS_REQUEST: '',
  MASTER_SCHEDULER: '',
  SUBMIT_DELETE_TO_IMS: '',
  SUBMIT_ACCESS_TO_IMS: '',
};

export const schecudledActionProcedureMap = {
  COPY_OUTBOUND_CALLINGLIST: 'copy_outbound_callinglist',
  PURGE_OUTBOUND: 'purge_outbound_callinglist',
  PURGE_GIM_DATA: 'adobe_gim_purge_gdpr_data',
  HASH_GDPR_REQUEST: 'adobe_gim_hash_gdpr_request',
  PURGE_UCS_CONTACT: 'adobe_purge_ucs_contact',
  SEARCH_GDPR_ACCESS_REQUEST: '',
  MASTER_SCHEDULER: '',
  SUBMIT_DELETE_TO_IMS: '',
  SUBMIT_ACCESS_TO_IMS: '',
};
export const REQUEST_STATUS_NAME_MAP = {
  0: 'New',
  4: 'DB Proccess Comleted',
  8: 'IMS Proccess Comleted',
  10: 'GDPR Request Purged',
};

export const GDPR_APP_ID = {
  156: 'APACSales_Non_English(156)',
  106: 'JapanEnterprise(106)',
  107: 'acrobat_sales(107)',
  108: 'adm(108)',
  109: 'adobe_exp_design_comet(109)',
  110: 'apac_sales(110)',
  111: 'apc(111)',
  112: 'cce(112)',
  113: 'cct_admin(113)',
  114: 'cct_support(114)',
  115: 'connect_pro_hosted(115)',
  116: 'CVGSimple(116)',
  117: 'CVGSMB(117)',
  118: 'dma(118)',
  119: 'DMA_STG(119)',
  120: 'DMA_T2(120)',
  158: 'NASalesWeekend(158)',
  160: 'JapanGO_Cart(160)',
  161: 'apac_country_sales(161)',
  104: 'hb_nicerecording(104',
};

export const GRANULARITY = {
  month: 'Month',
  year: 'Year',
};

const getEnvironment = () => {
  const { hostname } = window.location;
  console.log("hostname: ", hostname);
  let baseUrl = '';
  switch (hostname) {
    case 'localhost':
      baseUrl = 'https://localhost:8443';
      break;
    case 'dxoc-care-client-deploy-ethos502-stage-or2-k8s-h-9c7d0e.stage.cloud.adobe.io': //Dev
      baseUrl = 'https://dxoc-careserver-dev.adobe.io';
      break;
    case 'care-dev.adobe.com': //Dev
      baseUrl = 'https://dxoc-careserver-dev.adobe.io';
      break;
    case 'dxoc-care-client-deploy-ethos503-stage-va6-k8s-h-74b1dc.stage.cloud.adobe.io': //QE
      baseUrl = 'https://dxoc-careserver-qe.adobe.io';
      break;
    case 'care-qe.adobe.com': //QE
      baseUrl = 'https://dxoc-careserver-qe.adobe.io';
      break;
    case 'dxoc-care-client-deploy-ethos503-stage-va6-k8s-h-c10035.stage.cloud.adobe.io': //Stage
      baseUrl = 'https://dxoc-careserver-stage.adobe.io';
      break;
    case 'care-stage.adobe.com': //Stage
      baseUrl = 'https://dxoc-careserver-stage.adobe.io';
      break;
    case 'dxoc-care-client-deploy-ethos503-prod-va6-k8s-he-ee9803.cloud.adobe.io': //PROD
      baseUrl = 'https://dxoc-careserver.adobe.io';
      break;
    case 'care.adobe.com': //prod
      baseUrl = 'https://dxoc-careserver.adobe.io';
      break;
    default:
      baseUrl = 'https://dxoc-careserver-stage.adobe.io';
      break;
  }
  return baseUrl;
};

const getAlertEnvironment = () => {
  const { hostname } = window.location;
  let baseUrl = '';
  let xAPIKey = '';
  let amazonConnectUsURL='';
  let amazonConnectSgURL='';
  switch (hostname) {
    case 'localhost':
      baseUrl = 'https://dxoc-alertservice-dev.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE_STAGE';
      amazonConnectUsURL = "https://adobe.okta.com/app/amazon_aws/exk1skqo4lcBxCb6D0h8/sso/saml";
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1yktff9uiCAAr50h8/sso/saml"
      break;
    case 'dxoc-care-client-deploy-ethos502-stage-or2-k8s-h-9c7d0e.stage.cloud.adobe.io': //Dev
      baseUrl = 'https://dxoc-alertservice-dev.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE_STAGE';
      amazonConnectUsURL = "https://adobe.okta.com/app/amazon_aws/exk1skqo4lcBxCb6D0h8/sso/saml";
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1yktff9uiCAAr50h8/sso/saml"
      break;
    case 'dxoc-care-client-deploy-ethos503-stage-va6-k8s-h-74b1dc.stage.cloud.adobe.io': //QE
      baseUrl = 'https://dxoc-alertservice-qe.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE_STAGE';
      amazonConnectUsURL="https://adobe.okta.com/app/amazon_aws/exk1ttzgqsandabE10h8/sso/saml"
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1ynsl7i6l5xCUG0h8/sso/saml"
      break;
    case 'dxoc-care-client-deploy-ethos503-stage-va6-k8s-h-c10035.stage.cloud.adobe.io': //Stage
      baseUrl = 'https://dxoc-alertservice-stage.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE_STAGE';
      amazonConnectUsURL="https://adobe.okta.com/app/amazon_aws/exk1tqku85yiaB3HV0h8/sso/saml"
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1yswkqg358IFli0h8/sso/saml"
      break;
    case 'dxoc-care-client-deploy-ethos503-prod-va6-k8s-he-ee9803.cloud.adobe.io': //PROD
      baseUrl = 'https://dxoc-alertservice.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE';
      amazonConnectUsURL="https://adobe.okta.com/app/amazon_aws/exk1w6dm0xtMAHo9I0h8/sso/saml"
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1ys5p86o7aIdCl0h8/sso/saml"
      break;
    case 'care-qe.adobe.com': //QE
      baseUrl = 'https://dxoc-alertservice-qe.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE_STAGE';
      amazonConnectUsURL="https://adobe.okta.com/app/amazon_aws/exk1ttzgqsandabE10h8/sso/saml"
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1ynsl7i6l5xCUG0h8/sso/saml"
      break;
    case 'care-stage.adobe.com': //Stage
      baseUrl = 'https://dxoc-alertservice-stage.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE_STAGE';
      amazonConnectUsURL="https://adobe.okta.com/app/amazon_aws/exk1tqku85yiaB3HV0h8/sso/saml"
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1yswkqg358IFli0h8/sso/saml"
      break;
    case 'care.adobe.com': //prod
      baseUrl = 'https://dxoc-alertservice.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE';
      amazonConnectUsURL="https://adobe.okta.com/app/amazon_aws/exk1w6dm0xtMAHo9I0h8/sso/saml"
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1ys5p86o7aIdCl0h8/sso/saml"
      break;
    default: //Stage
      baseUrl = 'https://dxoc-alertservice-stage.adobe.io';
      xAPIKey = 'CES_ALERT_SERVICE_STAGE';
      amazonConnectUsURL="https://adobe.okta.com/app/amazon_aws/exk1tqku85yiaB3HV0h8/sso/saml"
      amazonConnectSgURL = "https://adobe.okta.com/app/amazon_aws/exk1yswkqg358IFli0h8/sso/saml"
      break;
  }
  return { baseUrl, xAPIKey, amazonConnectUsURL , amazonConnectSgURL };
};

export const ALERT_TYPES = ["convWait", "convAbandoned", "voiceMail", "onQueueUsers"];
export const SERVER_URL = getEnvironment();
export const ALERT_SERVER_URL = getAlertEnvironment();

export const toastObj = {
  position: toast.POSITION.BOTTOM_RIGHT,
  autoClose: 2000,
  containerId: 'defaultToast'
  // theme: "colored",
}

export const notify = (text, type, modprop={}) => {
  if (type === 'warn') {
    toast.warn(text, {...toastObj, ...modprop})
  } else if (type === 'success') {
    toast.success(text, {...toastObj, ...modprop})
  } else if (type === 'error') {
    toast.error(text, {...toastObj, ...modprop})
  }
}

export const PheonixRoles = {
  agentGroup: 'Agent Group Mapping',
  agentGroupAdmin: 'Agent Group Mapping Admin',
  agentGroupConfig: 'Agent Group Config Mapping',
  agentGroupConfigAdmin: 'Agent Group Config Mapping Admin',
  defaultConfig: 'Default Config Mapping',
  defaultConfigAdmin: 'Default Config Mapping Admin',
  voiceComplaince: 'Voice Compliance',
  voiceComplainceAdmin: 'Voice Compliance Admin',
  routingFlowConfig: 'Routing Flow Config',
  routingFlowConfigAdmin: 'Routing Flow Config Admin',
  attributeList: 'Attribute List Mapping',
  attributeListAdmin: 'Attribute List Mapping Admin',
  userGroupQueuesAndProficienciesAccess:'User Group Queues & Proficiencies Access',
  userAdditionalQueuesAndProficienciesAccess:'User Additional Queues & Proficiencies Access',
  groupConfigEnforceUpdateAccess:'Group Config Enforce Update Access',
  groupConfigAddAccess:'Group Config Add Access',
  groupConfigCloneAccess:'Group Config Clone Access',
  timeout :'Timeout',
  timeoutAdmin :'Timeout Admin',
  aniBypass :"Ani Bypass",
  aniBypassAdmin :'Ani Bypass Admin',
  holidaySchedules :'Holiday Schedules',
  holidaySchedulesAdmin :'Holiday Schedules Admin',
  emergency :'Emergency',
  emergencyAdmin :'Emergency Admin',
  dxPrompts :'Dx Prompts',
  dxPromptsAdmin :'Dx Prompts Admin',
  dnis :'Dnis',
  dnisAdmin :'Dnis Admin',
  didMagento :'Did Magento',
  didMagentoAdmin :'Did Magento Admin',
  srsMockData :'Srs Mock Data',
  srsMockDataAdmin :'Srs Mock Data Admin',
  spamCallers :'Spam Callers',
  spamCallersAdmin :'Spam Callers Admin',
  operationalHours :'Operational Hours',
  supervisior :'AC Supervisior',
  supervisiorAdmin :'AC Supervisior Admin',
}

export const SRSRoles = {
  srsRules: 'SRS Rules',
  srsRulesAdmin: 'SRS Rules Admin',
  srsProductFamily: 'SRS Product Family',
  srsProductFamilyAdmin: 'SRS Product Family Admin',
  systemTestData: 'System Test Data',
  systemTestDataAdmin: 'System Test Data Admin',
  caseHistory: 'Case History',
  caseHistoryAdmin: 'Case History Admin', 
}

export const caseHistoryNumericIDLength = {
  E: 9,
  PR: 9,
}

export const caseHistoryRequestType = {
  searchFilter: 'search',
  durationRequest: 'duration'
}

export const groupConfigSortingKeysPattern = {
  queues_assigned : ['queue_name', 'channel', 'priority','delay'],
  agent_states: ['state','routable'],
  routing_profile_concurrency: ['media','cross_channel_allow','max_concurrency']
}

// Create Routing Structure
/**
 * Currently making the structure 
 * later on plan to make an Interface with optional keys
 * Current structure
 * title
 * key
 * permission
 * children : (optional)
 */

export const tabsRoutingList = [
  {
  title:'AMAZON CONNECT',
  permission:'AMAZON CONNECT',
  key:'amazon_connect',
  children:[
    {
      title:'USERS',
      permission:'USERS',
      key:'agent_groups',
    },
    {
      title:'SUPERVISORS',
      permission:'SUPERVISORS',
      key:'supervisior',
    },
    {
      title:'GROUP CONFIG',
      permission:'GROUP CONFIG',
      key:'agentgroupconfig',
    },
    {
      title:'VOICE COMPLIANCE',
      permission:'VOICE COMPLIANCE',
      key:'voiceComplaince',
    },
    {
      title:'ROUTING FLOW CONFIG',
      permission:'ROUTING FLOW CONFIG',
      key:'commonConfig',
      children:[
        {
          title:'TIMEOUT',
          permission:'ROUTING FLOW CONFIG:TIMEOUT',
          key:'timeout'
        },
        {
          title:'HOLIDAY SCHEDULES',
          permission:'ROUTING FLOW CONFIG:HOLIDAY SCHEDULES',
          key:'holidaySchedules'
        },
        {
          title:'EMERGENCY',
          permission:'ROUTING FLOW CONFIG:EMERGENCY',
          key:'emergency'
        },
        {
          title:'DX PROMPTS',
          permission:'ROUTING FLOW CONFIG:DX PROMPTS',
          key:'dxprompts'
        },
        {
          title:'PHONE NUMBERS',
          permission:'ROUTING FLOW CONFIG:PHONE NUMBERS',
          key:'dnis'
        },
        {
          title:'DID MAGENTO',
          permission:'ROUTING FLOW CONFIG:DID MAGENTO',
          key:'didmagento'
        },
        {
          title:'SRS MOCK DATA',
          permission:'ROUTING FLOW CONFIG:SRS MOCK DATA',
          key:'mockdata'
        },
        {
          title:'SPAM CALLERS',
          permission:'ROUTING FLOW CONFIG:SPAM CALLERS',
          key:'spamCallers'
        },
        {
          title:'OPERATIONAL HOURS',
          permission:'ROUTING FLOW CONFIG:OPERATIONAL HOURS',
          key:'timezoneCallDuration'
        },
        {
          title:'ANI BYPASS',
          permission:'ROUTING FLOW CONFIG:ANI BYPASS',
          key:'aniBypass'
        },
        {
          title:'ROUTING CRITERIA',
          permission:'ROUTING FLOW CONFIG:ROUTING CRIETERIA',
          key:'routingCriteria'
        },
      ]
    },
    {
      title:'ATTRIBUTE LIST',
      permission:'ATTRIBUTE LIST',
      key:'attributeList',
    }
  ]
},{
  title:'SRS',
  permission:'SRS',
  key:'srs',
  children:[
    {
      title:'CASE HISTORY',
      permission:'CASE HISTORY',
      key:'caseHistory',
    },
    {
      title:'SRS RULE',
      permission:'SRS RULE',
      key:'srsRule',
    },
    {
      title:'SRS PRODUCT FAMILY',
      permission:'SRS PRODUCT FAMILY',
      key:'srsProd',
    },
    {
      title:'SYSTEM TEST DATA',
      permission:'SYSTEM TEST DATA',
      key:'systemTestData',
    }
  ]
},
{
  title:'ZEON',
  permission:'ZEON',
  key:'zeon',
  children:[
    {
      title:'ORG FAMILY',
      permission:'ORG FAMILY',
      key:'orgFamily',
    },
    {
      title:'EXCEPTION RULES',
      permission:'EXCEPTION RULES',
      key:'exceptionRules',
    },
  ]
},
{
  title:'NOTIFICATION CONTROL PANEL',
  permission:'ALERT',
  key:'alert_service',
  children:[{
    title:'QUEUE MONITORING',
    permission:'QUEUE MONITORING',
    key:'queue_monitoring'
  },{
    title:'OTHERS MONITORING',
    permission:'OTHERS MONITORING',
    key:'others_monitoring'
  }
]
},{
  title:'ADMIN',
  permission:'ADMIN',
  key:'admin',
  children:[{
    title:'CARE USERS',
    permission:'CARE USERS',
    key:'users',
  },{
    title:'CARE ROLES',
    permission:'CARE ROLES',
    key:'roles',
  },{
    title:'POLICIES',
    permission:'POLICIES',
    key:'policies',
  },{
    title:'TIMEZONES',
    permission:'TIMEZONES',
    key:'timezone',
  },{
    title:'PERMISSIONS',
    permission:'PERMISSIONS',
    key:'permissions',
  }]
},
{
  title:'REPORTS',
  permission:'REPORTS',
  key:'reports',
  children:[{
    title:'USER PROFILE',
    permission:'USER PROFILE',
    key:'users_profile',
  },
  {
    title:'AUDIT REPORT',
    permission:'AUDIT REPORT',
    key:'audit_report',
  }]
}
]