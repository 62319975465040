import React, { useState, useRef, useEffect } from "react"
// import 'react-schedule-job/dist/index.css'
// import './index.css';
import { connect, useStore } from "react-redux"
import {
    Flex,
    Heading,
    TableBody,
    View,
    Item,
    TableHeader,
    Button,
    TableView,
    SearchField,
    ActionGroup,
    Text,
    Column,
    IllustratedMessage,
    Content,
    Row,
    Cell,
    Badge,
    DialogTrigger,
    Dialog,
    ActionButton,
    ProgressCircle,
    Divider,
    ComboBox,
    MenuTrigger,
    Menu,
    DatePicker,
    ButtonGroup,
    TextField,
    Picker,
} from "@adobe/react-spectrum"
import {
    fetchInstance,
    getDefaultGroupsConfig,
    getAllAttributeListAndValues,
    getAllSupervisiorGroupList,
    getAllAgentGroupList,
    getAllGroupNames,
} from "../../utilities/api/phoenix-api"
import { getAuditTrailReport } from "../../utilities/api/reports-api"
// import Delete from '@spectrum-icons/workflow/Delete';
// import Draw from '@spectrum-icons/workflow/Draw';
// import Refresh from "@spectrum-icons/workflow/Refresh"
// import { notify } from '../../../constants/constants';
import { renderEmptyState } from "../../utilities/components/CommonUseComponents"
import ChevronDown from "@spectrum-icons/workflow/ChevronDown"
import Filter from "@spectrum-icons/workflow/Filter"
// import NotFound from '@spectrum-icons/illustrations/NotFound';
import { NotFoundIconSvg } from "../../utilities/components/Icons"
import Search from "@spectrum-icons/workflow/Search"
import {
    parseAbsoluteToLocal,
    parseDate,
    parseAbsolute,
    parseDateTime,
    parseZonedDateTime,
    toZoned,
    toCalendarDateTime,
} from "@internationalized/date"
import User from "@spectrum-icons/workflow/User"
import UserGroup from "@spectrum-icons/workflow/UserGroup"
import Clock from "@spectrum-icons/workflow/Clock"
import Wrench from "@spectrum-icons/workflow/Wrench"
import Compare from "@spectrum-icons/workflow/Compare"
import Properties from "@spectrum-icons/workflow/Properties"
import Visibility from "@spectrum-icons/workflow/Visibility"
import TaskList from "@spectrum-icons/workflow/TaskList"
import Close from "@spectrum-icons/workflow/Close"
import { DataComparison } from "./DataComparison"
import ChevronRight from "@spectrum-icons/workflow/ChevronRight"
import ChevronLeft from "@spectrum-icons/workflow/ChevronLeft"
import Download from "@spectrum-icons/workflow/Download"
import { getTimezonesAPI } from "../../utilities/api/admin-api"
import * as XLSX from "xlsx"
import { get } from "@okta/okta-auth-js"

//remove other unused files
// remove console.log
//change the hard coded user value to dynamically fetched ldap
//remove commented code
// Timezone UST
// mapping for GROUP_CONFIG_CHANGE

const getCurrentDayStart = () => {
    const now = new Date()
    now.setHours(0, 0, 0, 0)
    return now.toISOString().split(".")[0] + "Z"
}

const getCurrentTime = () => {
    return new Date().toISOString().split(".")[0] + "Z"
}

const AuditReport = ({ user }) => {
    const [inputValue, setInputValue] = useState("")

    const [startDate, setStartDate] = useState(
        parseAbsoluteToLocal(getCurrentDayStart())
    )
    const [endDate, setEndDate] = useState(
        parseAbsoluteToLocal(getCurrentTime())
    )

    useEffect(() => {
        setStartDate(parseAbsoluteToLocal(getCurrentDayStart()))
        setEndDate(parseAbsoluteToLocal(getCurrentTime()))
    }, [])

    const columns = [
        { name: "User", key: "username", icon: <User height={"10px"} /> },
        { name: "Group", key: "group", icon: <UserGroup height={"10px"} /> },
        {
            name: "Event Type",
            key: "eventType",
            icon: <Properties height={"10px"} />,
        },
        {
            name: "Trigger Type",
            key: "triggerType",
            icon: <Properties height={"10px"} />,
        },
        {
            name: "Status",
            key: "executionStatus",
            icon: <TaskList height={"10px"} />,
        },
        { name: "Modifier", key: "modifier", icon: <User height={"10px"} /> },
        {
            name: "Source",
            key: "source",
            icon: <Wrench height={"10px"} />,
        },
        {
            name: "Timestamp",
            key: "timestamp",
            icon: <Clock height={"10px"} />,
        },
        { name: "Delta", key: "diff", icon: <Compare height={"10px"} /> },
    ]

    const eventTypes = [
        // { name: "USER CREATE", key: "USER_CREATE" },
        { name: "USER DELETE", key: "USER_DELETE" },
        // { name: "USER EDIT", key: "USER_EDIT" },
        // { name: "GROUP CREATE", key: "GROUP_CREATE" },
        { name: "USER QUEUE ADD", key: "USER_QUEUE_ADD" },
        { name: "USER QUEUE REMOVE", key: "USER_QUEUE_REMOVE" },
        // { name: "USER QUEUE UPDATE", key: "USER_QUEUE_UPDATE" },
        { name: "USER PROFICIENCY ADD", key: "USER_PROFICIENCY_ADD" },
        { name: "USER PROFICIENCY REMOVE", key: "USER_PROFICIENCY_REMOVE" },
        { name: "USER PROFICIENCY UPDATE", key: "USER_PROFICIENCY_UPDATE" },
        { name: "SUPERVISOR DELETE", key: "SUPERVISOR_DELETE" },
        { name: "SUPERVISOR CREATE", key: "SUPERVISOR_CREATE" },
        { name: "USER GROUP CHANGE", key: "USER_GROUP_CHANGE" },
        { name: "GROUP CONFIG CHANGE", key: "GROUP_CONFIG_CHANGE" },
    ]

    const statuses = [
        { name: "COMPLETED", key: "COMPLETED" },
        { name: "FAILED", key: "FAILED" },
    ]

    const triggerTypes = [
        { name: "ENFORCE UPDATE", key: "ENFORCE_UPDATE" },
        { name: "ADD USER", key: "ADD_USER" },
        { name: "EDIT USER", key: "EDIT_USER" },
        { name: "DELETE USER", key: "DELETE_USER" },
        { name: "USER GROUP CHANGE", key: "USER_GROUP_CHANGE" },
        // { name: "BULK CREATE USER", key: "BULK_CREATE_USER" },
        { name: "ADD SUPERVISOR", key: "ADD_SUPERVISOR" },
        { name: "DELETE SUPERVISOR", key: "DELETE_SUPERVISOR" },
        { name: "GROUP CONFIG ADD", key: "GROUP_CONFIG_ADD" },
        { name: "GROUP CONFIG EDIT", key: "GROUP_CONFIG_EDIT" },
    ]

    const initiationPlatforms = [
        { name: "CARE PORTAL", key: "CARE_PORTAL" },
        { name: "IAM", key: "IAM" },
    ]

    // const timezones = [
    //     { name: "UST", key: "UST" },
    //     { name: "IST", key: "IST" },
    //     { name: "CST", key: "CST" },
    //     { name: "PST", key: "PST" },
    //     { name: "EST", key: "EST" },
    //     { name: "MST", key: "MST" },
    //     { name: "GMT", key: "GMT" },
    //     { name: "CET", key: "CET" },
    // ]

    const initialData = {
        username: "",
        group: "",
        eventType: "",
        modifier: "",
        executionStatus: "",
        triggerType: "",
        source: "",
        timezone: "Asia/Kolkata",
        startTime: startDate,
        endTime: endDate,
    }

    const [isLoading, setIsLoading] = useState(true)
    const [formData, setFormData] = useState(initialData)
    const [data, setData] = useState([])
    const [allUsers, setAllUsers] = useState([])
    const [allGroups, setAllGroups] = useState([])
    const [allInstances, setAllInstances] = useState([])
    const [selectedInstance, setSelectedInstance] = useState()
    const [selectedType, setSelectedType] = useState()
    const [allValues, setAllValues] = useState()
    const [selectedValues, setSelectedValues] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [allProficiencies, setAllProficiencies] = useState([])
    const [allAttributeNames, setAllAttributeNames] = useState([])
    const [selectedAttributeName, setSelectedAttributeName] = useState()
    const [selectedAttributeValue, setSelectedAttributeValue] = useState()
    const [isCollapsed, setIsCollapsed] = useState(false)
    const [allTimezones, setAllTimezones] = useState([])
    const [headerTimezone, setHeaderTimezone] = useState("IST")

    const toggleCollapse = () => {
        setIsCollapsed((prev) => !prev)
    }

    useEffect(() => {
        getAllUsers()
        getAllGroups()
        getAllTimezones()
    }, [])

    // getting the timezone abbr
    useEffect(() => {
        // if (allTimezones?.length > 0) {
        //     const getTimezone = allTimezones.find(
        //         (item) => item?.zoneId === formData?.timezone
        //     )
        //     setHeaderTimezone(getTimezone?.abbr)
        // }
        if (!formData.timezone) {
            setFormData((prev) => ({
                ...prev,
                timezone: "Asia/Kolkata",
            }))
        }
    }, [formData.timezone])

    function getAllTimezones() {
        getTimezonesAPI()
            .then((timezone) => {
                setIsLoading(false)
                setAllTimezones(timezone)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const auditTrailReportAPIcall = () => {
        setIsLoading(true)
        // need to change it after correcting the time issue atleast all the data is going now
        getAuditTrailReport(formData)
            .then((response) => {
                console.log("response", response)

                const zone = allTimezones?.find(
                    (item) => item.zoneId === response[0]?.timeZone
                )?.abbr

                setHeaderTimezone(zone)
                setData(response)
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const getAllGroups = () => {
        setIsLoading(true)
        getAllGroupNames()
            .then((response) => {
                const removeFirstAndLast = response.slice(1, -1)
                const result = removeFirstAndLast.split(",").map((item) => {
                    return {
                        key: item.trim(),
                        name: item.trim(),
                    }
                })

                setIsLoading(false)
                setAllGroups(result)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error)
            })
    }
    const getAllUsers = async () => {
        try {
            setIsLoading(true)
            const [supervisorGroups, agentGroups] = await Promise.all([
                getAllSupervisiorGroupList(user),
                getAllAgentGroupList(user),
            ])

            const combinedUsers = [...supervisorGroups, ...agentGroups]

            const uniqueUsers = Array.from(
                new Map(
                    combinedUsers.map((user) => [user.agentLdap, user])
                ).values()
            )

            const formattedUsers = uniqueUsers.map((user) => ({
                ldap: user.agentLdap,
                name: `${user.firstName} ${user.lastName}`.trim(),
            }))

            setAllUsers(formattedUsers)
        } catch (error) {
            console.error("Error fetching users:", error)
        } finally {
            setIsLoading(false)
        }
    }

    // console.log("allUsers", allUsers)

    // const getAllGroups = () => {

    // const getAllProficiencies = () => {
    //     setIsLoading(true)
    //     getAllAttributeListAndValues()
    //         .then((response) => {
    //             setIsLoading(false)
    //             const attributeNames = Object.keys(
    //                 response[selectedInstance]
    //             )?.map((item) => ({ name: item }))
    //             const attributeValues = Object.values(
    //                 response[selectedInstance]
    //             )
    //             setAllProficiencies(response[selectedInstance])
    //             setAllAttributeNames(attributeNames)
    //             // setAllAttributeValues(attributeValues)
    //         })
    //         .catch((error) => {
    //             setIsLoading(false)
    //             console.log(error)
    //         })
    // }

    // const getAllInstances = () => {
    //     setIsLoading(true)
    //     fetchInstance()
    //         .then((response) => {
    //             setIsLoading(false)
    //             setAllInstances(response)
    //         })
    //         .catch((error) => {
    //             setIsLoading(false)
    //             console.log(error)
    //         })
    // }

    // function sortDataByName(data) {
    //     return data?.sort((a, b) => {
    //         if (a.name < b.name) return -1
    //         if (a.name > b.name) return 1
    //         return 0
    //     })
    // }

    // const getAllQueues = () => {
    //     // if (instance) {
    //     setIsLoading(true)
    //     getDefaultGroupsConfig()
    //         .then((response) => {
    //             setIsLoading(false)
    //             setAllValues(response)
    //         })
    //         .catch((error) => {
    //             setIsLoading(false)
    //             console.log(error)
    //         })
    //     // }
    // }

    // const handleSearchChange = (value) => {
    //     setSearchTerm(value)
    //     const filteredData = searchResults.filter((item) =>
    //         item.name.toLowerCase().includes(value.toLowerCase())
    //     )
    //     setFilteredStructuredData(filteredData)
    // }

    const handleFilter = () => {
        const formattedStartDate = startDate.toString().split("+")[0]
        const formattedEndDate = endDate.toString().split("+")[0]
        formData.startTime = formattedStartDate
        formData.endTime = formattedEndDate
        setData([])
        auditTrailReportAPIcall()
    }

    const handleClear = () => {
        resetData()
    }

    const resetData = () => {
        setFormData(initialData)
        setInputValue("")
        setStartDate(parseAbsoluteToLocal(getCurrentDayStart()))
        setEndDate(parseAbsoluteToLocal(getCurrentTime()))
    }

    const renderBadge = (text, bgColor, textColor) => {
        return (
            <Badge
                UNSAFE_style={{
                    backgroundColor: bgColor,
                    color: textColor,
                    padding: "4px",
                    borderRadius: "4px",
                    fontSize: "12px",
                    fontWeight: "700",
                }}
            >
                {text}
            </Badge>
        )
    }

    const getCell = (columnKey, item) => {
        // console.log("item", item)

        switch (columnKey) {
            case "executionStatus":
                return (
                    <Cell>
                        {item[columnKey] === "COMPLETED"
                            ? renderBadge(item[columnKey], "#dcfce7", "#265b3e")
                            : renderBadge(
                                  item[columnKey],
                                  "#fee2e1",
                                  "#882e2f"
                              )}
                    </Cell>
                )
            case "triggerType":
                return (
                    <Cell>
                        {renderBadge(item[columnKey], "#dbeaff", "#2e467d")}
                    </Cell>
                )
            case "timestamp":
                return (
                    <Cell>
                        {item[columnKey]}
                    </Cell>
                )
            case "diff":
                return (
                    <Cell>
                        <DialogTrigger type="fullscreen">
                            <ActionButton>
                                <Visibility />
                            </ActionButton>
                            {(close) => (
                                <Dialog>
                                    <Heading>Delta</Heading>

                                    <Divider />
                                    <Content>
                                        <div className="p-6">
                                            <DataComparison entry={item} />
                                        </div>
                                    </Content>
                                    <ButtonGroup>
                                        <Button
                                            variant="negative"
                                            onPress={close}
                                        >
                                            <Close />
                                        </Button>
                                    </ButtonGroup>
                                </Dialog>
                            )}
                        </DialogTrigger>
                    </Cell>
                )
            default:
                return <Cell>{item[columnKey]}</Cell>
        }
    }

    const fetchProficiencies = () => {
        const proficiencies = allProficiencies[
            selectedAttributeName
        ]?.map((item) => ({ name: item }))
        return proficiencies
    }

    const handleSelectionChange = (key, value) => {
        setFormData({ ...formData, [key]: value })
    }

    const downloadAuditTrailReport = () => {
        const excelData = data?.map((item) => ({
            username: item.username,
            group: item.group,
            eventType: item.eventType,
            modifier: item.modifier,
            executionStatus: item.executionStatus,
            triggerType: item.triggerType,
            source: item.source,
            timestamp: item.timestamp,
            // oldData: JSON.stringify(item.oldData || []),
            // newData: JSON.stringify(item.newData || []),
            added: JSON.stringify(item.diff?.added || []),
            removed: JSON.stringify(item.diff?.removed || []),
            // diff: JSON.stringify(item.diff || {}),
        }))

        const worksheet = XLSX.utils.json_to_sheet(excelData)

        const columnWidths = [
            { wch: 15 }, // username
            { wch: 15 }, // group
            { wch: 20 }, // eventType
            { wch: 15 }, // modifier
            { wch: 20 }, // executionStatus
            { wch: 15 }, // triggerType
            { wch: 20 }, // source
            { wch: 25 }, // timestamp
            // { wch: 100 }, // oldData
            // { wch: 100 }, // newData
            { wch: 100 }, // added
            { wch: 100 }, // removed
            // { wch: 100 }, // diff
        ]

        worksheet["!cols"] = columnWidths

        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, "Audit Report")

        XLSX.writeFile(workbook, "audit_trail_report.xlsx")
    }

    const filteredUsers = allUsers?.filter(
        (user) =>
            user.name.toLowerCase().includes(inputValue.toLowerCase()) ||
            user.ldap.toLowerCase().includes(inputValue.toLowerCase())
    )

    return (
        <>
            {isLoading ? (
                <div
                    className={isLoading ? "display-block" : "display-none"}
                    style={{
                        paddingTop: "20%",
                        paddingLeft: "55%",
                        position: "absolute",
                        zIndex: "10",
                        // width: "100%",
                    }}
                >
                    <ProgressCircle
                        aria-label="Loading…"
                        isIndeterminate
                        size="M"
                    />
                </div>
            ) : null}
            <Flex justifyContent="space-between" alignItems="center">
                <Flex flex={1} justifyContent="center">
                    <Heading variant="pageTitle">Audit Report</Heading>
                </Flex>
                <ActionButton
                    onPress={downloadAuditTrailReport}
                    variant={data.length == 0 ? "secondary" : "cta"}
                    isDisabled={data.length === 0}
                    marginTop={"12px"}
                    UNSAFE_style={{ padding: "0 8px 0 0" }}
                >
                    <Download /> Report
                </ActionButton>
            </Flex>
            <div
                style={{
                    padding: "25px",
                    border: "0.8px solid",
                    borderRadius: "0.7em",
                    marginTop: "8px",
                    height: "65vh",
                    width: "100%",
                    display: "flex",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        width: isCollapsed ? "50px" : "350px",
                        minWidth: isCollapsed ? "50px" : "350px",
                        maxWidth: "350px",
                        padding: isCollapsed ? "10px" : "20px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                        borderRight: "1px solid #e0e0e0",
                        overflowY: "auto",
                        transition: "width 0.3 s ease-in-out",
                        scrollbarWidth: "1px",
                        scrollbarColor: "#ffffff #ffffff",
                    }}
                >
                    {!isCollapsed && (
                        <Flex direction="column" gap={10}>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Users
                                </Heading>
                                <ComboBox
                                    items={
                                        inputValue ? filteredUsers : allUsers
                                    }
                                    // selectedKey={formData?.username}
                                    inputValue={inputValue}
                                    onInputChange={setInputValue}
                                    onSelectionChange={(value) =>
                                        handleSelectionChange("username", value)
                                    }
                                    width="calc(100% - 100px)"
                                    allowsCustomValue={false}
                                >
                                    {(item) => (
                                        <Item key={item?.ldap}>
                                            {item?.name}
                                        </Item>
                                    )}
                                </ComboBox>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <Text width="100px" color="gray">
                                Type
                            </Text> */}
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Group
                                </Heading>
                                <ComboBox
                                    defaultItems={allGroups}
                                    selectedKey={formData.group}
                                    onSelectionChange={(value) =>
                                        handleSelectionChange("group", value)
                                    }
                                    width="calc(100% - 100px)"
                                    // isDisabled={!selectedInstance}
                                >
                                    {(item) => (
                                        <Item key={item.key}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <Text width="100px" color="gray">
                                Type
                            </Text> */}
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Event Type
                                </Heading>
                                <ComboBox
                                    defaultItems={eventTypes}
                                    selectedKey={formData.eventType}
                                    onSelectionChange={(value) =>
                                        handleSelectionChange(
                                            "eventType",
                                            value
                                        )
                                    }
                                    width="calc(100% - 100px)"
                                    // isDisabled={!selectedInstance}
                                >
                                    {(item) => (
                                        <Item key={item.key}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <Text width="100px" color="gray">
                                Type
                            </Text> */}
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Trigger Type
                                </Heading>
                                <ComboBox
                                    defaultItems={triggerTypes}
                                    selectedKey={formData.triggerType}
                                    onSelectionChange={(value) =>
                                        handleSelectionChange(
                                            "triggerType",
                                            value
                                        )
                                    }
                                    width="calc(100% - 100px)"
                                    // isDisabled={!selectedInstance}
                                >
                                    {(item) => (
                                        <Item key={item.key}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <Text width="100px" color="gray">
                                Type
                            </Text> */}
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Modifier
                                </Heading>
                                <ComboBox
                                    defaultItems={allUsers}
                                    selectedKey={formData.modifier}
                                    onSelectionChange={(value) =>
                                        handleSelectionChange("modifier", value)
                                    }
                                    width="calc(100% - 100px)"
                                    // isDisabled={!selectedInstance}
                                >
                                    {(item) => (
                                        <Item key={item.ldap}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                {/* <Text width="100px" color="gray">
                                Type
                            </Text> */}
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Status
                                </Heading>
                                <ComboBox
                                    defaultItems={statuses}
                                    selectedKey={formData.executionStatus}
                                    onSelectionChange={(value) =>
                                        handleSelectionChange(
                                            "executionStatus",
                                            value
                                        )
                                    }
                                    width="calc(100% - 100px)"
                                    // isDisabled={!selectedInstance}
                                >
                                    {(item) => (
                                        <Item key={item.key}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>

                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    <View> Source</View>
                                </Heading>
                                <ComboBox
                                    defaultItems={initiationPlatforms}
                                    selectedKey={formData.source}
                                    onSelectionChange={(value) =>
                                        handleSelectionChange("source", value)
                                    }
                                    width="calc(100% - 100px)"
                                    // isDisabled={!selectedInstance}
                                >
                                    {(item) => (
                                        <Item key={item.key}>{item.name}</Item>
                                    )}
                                </ComboBox>
                            </Flex>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                                UNSAFE_className="custom-picker"
                            >
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    <View>Timezone</View>
                                </Heading>
                                <Picker
                                    items={allTimezones}
                                    selectedKey={
                                        formData.timezone ||
                                        allTimezones[4]?.zoneId
                                    }
                                    onSelectionChange={(value) =>
                                        handleSelectionChange("timezone", value)
                                    }
                                    width="calc(100% - 100px)"
                                >
                                    {(item) => (
                                        <Item key={item.zoneId}>
                                            {`${item?.name} (${item?.abbr})`}
                                        </Item>
                                    )}
                                </Picker>
                            </Flex>
                            {/* <DatePicker
                                label="Date and time"
                                granularity="second"

                                hideTimeZone
                                hourCycle={24}
                                value={date}
                                onChange={setDate}
                            /> */}
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    Start Time
                                </Heading>
                                <DatePicker
                                    hideTimeZone
                                    hourCycle={24}
                                    value={startDate}
                                    onChange={setStartDate}
                                    granularity="second"
                                    width="calc(100% - 100px)"
                                    aria-label="Start Time"
                                    maxValue={parseAbsoluteToLocal(
                                        getCurrentTime()
                                    )}
                                />
                            </Flex>
                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Heading
                                    level={6}
                                    width="100px"
                                    UNSAFE_style={{ color: "#999999" }}
                                >
                                    End Time
                                </Heading>
                                <DatePicker
                                    hideTimeZone
                                    hourCycle={24}
                                    value={endDate}
                                    onChange={setEndDate}
                                    granularity="second"
                                    width="calc(100% - 100px)"
                                    aria-label="End Time"
                                    minValue={startDate}
                                    maxValue={parseAbsoluteToLocal(
                                        getCurrentTime()
                                    )}
                                />
                            </Flex>
                            <Flex direction="column" gap={10} marginTop={10}>
                                <Button
                                    variant="cta"
                                    style="fill"
                                    width="100%"
                                    // isDisabled={
                                    //     !selectedInstance ||
                                    //     !selectedType ||
                                    //     !selectedValues ||
                                    //     !selectedAttributeName ||
                                    //     !selectedAttributeValue
                                    // }
                                    onPress={handleFilter}
                                >
                                    <Search marginEnd="5px" /> Apply Filters
                                </Button>
                                <Button
                                    variant="negative"
                                    style="outline"
                                    width="100%"
                                    onPress={handleClear}
                                >
                                    Clear
                                </Button>
                            </Flex>
                        </Flex>
                    )}
                </div>
                <div style={{ marginLeft: "-16px" }}>
                    <Button
                        variant="secondary"
                        onPress={toggleCollapse}
                        UNSAFE_style={{
                            alignSelf: "flex-end",
                            // marginTop: "30px",
                            backgroundColor: "#ebebeb",
                            cursor: "pointer",
                        }}
                    >
                        {isCollapsed ? (
                            <ChevronRight size="S" />
                        ) : (
                            <ChevronLeft size="S" />
                        )}
                    </Button>
                </div>
                {data.length > 0 && !isLoading && (
                    <TableView
                        UNSAFE_style={{ marginLeft: "10px" }}
                        aria-label="audit report"
                        height={"60vh"}
                        width={"100%"}
                        density="regular"
                        selectionMode="single"
                        selectionStyle="highlight"
                        // disabledKeys={["1"]}
                    >
                        <TableHeader columns={columns}>
                            {(column) => (
                                <Column
                                    key={column.key}
                                    allowsResizing
                                    align={
                                        column.key === "actions"
                                            ? "end"
                                            : "start"
                                    }
                                    minWidth={
                                        column.key === "timestamp" ||
                                        column.key === "eventType" ||
                                        column.key === "triggerType" ||
                                        column.key === "group"
                                            ? "22%"
                                            : "15%"
                                    }
                                >
                                    {column.icon}
                                    {column.key === "timestamp"
                                        ? `${column.name} (${
                                              headerTimezone || ""
                                          })`
                                        : column.name}
                                </Column>
                            )}
                        </TableHeader>
                        <TableBody items={data}>
                            {(item) => (
                                <Row key={`${item.username}-${item.timestamp}`}>
                                    {(columnKey) => getCell(columnKey, item)}
                                </Row>
                            )}
                        </TableBody>
                    </TableView>
                )}
                {!isLoading && data.length === 0 && (
                    <View width={"100%"}>
                        <IllustratedMessage>
                            <NotFoundIconSvg />
                            <Heading>No results found</Heading>
                        </IllustratedMessage>
                    </View>
                )}
            </div>
            {/* </div> */}
        </>
    )
}

export default AuditReport
