import React, { useState } from "react"
import {
    View,
    Heading,
    TableView,
    TableHeader,
    TableBody,
    Column,
    Row,
    Badge,
    Cell,
    TextField,
    Button,
    Flex,
} from "@adobe/react-spectrum"

// remove console
// add scoll and width for big data

export default function ComparisionTable({
    columns,
    title,
    variant,
    data,
    isColumn,
    type
}) {


    const [searchQuery, setSearchQuery] = useState("")
    const [filteredData, setFilteredData] = useState(data)

  


    const handleSearch = (query) => {
        setSearchQuery(query)
        if (query) {
            const lowerCaseQuery = query.toLowerCase()
            setFilteredData(
                data.filter((item) =>
                    Object.values(item).some(
                        (value) =>
                            typeof value === "string" &&
                            value.toLowerCase().includes(lowerCaseQuery)
                    )
                )
            )
        } else {
            setFilteredData(data)
        }
    }

    const copyTableContent = () => {
        const tableContent = filteredData
            .map((item) =>
                Object.values(item)
                    .map((val) => (val !== null ? val : ""))
                    .join("\t")
            )
            .join("\n")
        navigator.clipboard.writeText(tableContent)
        // alert("Table content copied to clipboard!")
    }

    function formatString(str) {
        return (
            str
                // Add space before capital letters (for camel case)
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                // Replace underscores with spaces
                .replace(/_/g, " ")
                // Capitalize the first letter of each word
                .replace(/\b\w/g, (char) => char.toUpperCase())
        )
    }

    return (
        <>
            <View borderRadius="medium" overflow="hidden" boxShadow="small">
                <View
                    padding="size-200"
                    UNSAFE_style={{
                        color: "white",
                        backgroundColor:
                            variant === "old"
                                ? "#bcbcbc"
                                : variant === "new"
                                ? "#dbeaff"
                                : variant === "added"
                                ? "#dcfce7"
                                : "#fee2e1",
                    }}
                >
                    <Heading
                        level={3}
                        margin="0"
                        UNSAFE_style={{
                            color:
                                variant === "old"
                                    ? "#464646"
                                    : variant === "new"
                                    ? "#2e467d"
                                    : variant === "added"
                                    ? "#265b3e"
                                    : "#882e2f",
                        }}
                    >
                        {title}
                    </Heading>
                </View>
                <Flex margin="size-200" gap="size-200" alignItems="center">
                    <TextField
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                        width="size-4600"
                    />
                </Flex>

                {isColumn ? ((
                        <TableView
                            aria-label={title}
                            margin="size-200"
                            UNSAFE_style={{
                                maxWidth: "100%",
                                overflow: "auto",
                            }}
                            height={200}
                        >
                            <TableHeader>
                                {columns?.map((column, index) => (
                                    <Column minWidth={(column === "queue_name" && type ==="small") ? "50%" : (column === "queue_name" && type ==="large") ? "35%" : null}>{formatString(column)}</Column>
                                ))}
                            </TableHeader>
                            <TableBody >
                                {filteredData?.map((item, index) => (
                                    <Row key={index}>
                                        {columns?.map((column) => (
                                            <>
                                                <Cell>
                                                    {item[column] === null ? (
                                                        "-"
                                                    ) : (
                                                        item[column]
                                                    )}
                                                </Cell>
                                            </>
                                        ))}
                                    </Row>
                                ))}
                            </TableBody>
                        </TableView>
                    )
                ) : (
                    <View
                        padding="size-200"
                        borderWidth="thin"
                        backgroundColor="gray-100"
                        alignItems="center"
                        justifyContent="center"
                        UNSAFE_style={{
                            marginTop: "54px",
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderColor: "#dadada",
                            height: "164px",
                            borderRadius: "8px",
                        }}
                    >
                        <Heading level={5}>Data Not Exist</Heading>
                    </View>
                )}
            </View>
        </>
    )
}
