import React, { Suspense, lazy } from 'react';
import {IllustratedMessage, Heading, Content, Tabs, TabList, TabPanels, Item, ProgressCircle} from '@adobe/react-spectrum';
import ErrorBoundary from './ErrorBoundary';
import AuditTrail from '../../Reports/AuditReport/AuditReport';

export function renderEmptyState() {
    return (
      <IllustratedMessage>
        <Content>No results found</Content>
      </IllustratedMessage>
    );
  }

// component for tabs display
/**
 * Below Is the code that would lazy load components 
 */
const Components = {
  amazon_connect: lazy(() => import("../../Phoenix/AmazonConnect")),
  srs: lazy(() => import("../../SRS/SRS")),
  alert_service: lazy(() => import("../../AlertService/AlertService")),
  admin: lazy(() => import("../../AdminConsole/AdminOperations")),
  caseHistory: lazy(() => import("../../SRS/CaseHistory/CaseHistory")),
  srsRule: lazy(() => import("../../SRS/SRSRules/SRSRules")),
  srsProd: lazy(() => import("../../SRS/SRSProductFamily/SRSProductFamily")),
  systemTestData: lazy(() => import("../../SRS/ServiceTestData/ServiceTestList")),
  users: lazy(() => import("../../AdminConsole/UserManagement")),
  roles: lazy(() => import("../../AdminConsole/RoleManagement")),
  timezone: lazy(() => import("../../AdminConsole/TimezoneManagement")),
  queue_monitoring: lazy(() => import("../../AlertServiceNew/AlertServiceList")),
  others_monitoring: lazy(() => import("../../AlertBuMonitoring/BuAlertList")),
  policies: lazy(()=> import("../../AdminConsole/Policies")),
  supervisior: lazy(()=> import("../../Phoenix/ChildComponents/Supervisior/AgentGroupList")),
  agent_groups: lazy(()=> import("../../Phoenix/ChildComponents/AgentGroups/AgentGroupList")),
  agentgroupconfig: lazy(()=> import("../../Phoenix/ChildComponents/GroupConfig/AgentGroupMapping")),
  voiceComplaince: lazy(()=> import("../../Phoenix/ChildComponents/VoiceCompliance/VoiceComplianceList")),
  commonConfig: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/CommonConfig")),
  attributeList: lazy(()=> import("../../Phoenix/ChildComponents/AttributeList/AttributeList")),
  timeout: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/Timeout/Timeout")),
  holidaySchedules: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/HolidaySchedule/HolidaySchedule")),
  emergency: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/Emergency/DxocEmergency")),
  dxprompts: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/DxPrompts/DxPrompts")),
  dnis: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/Dnis/Dnis")),
  didmagento: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/DidMagento/DidMagento")),
  mockdata: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/SrsMockData/SrsMockData")),
  spamCallers: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/spamContactsDashboard/SpamCallersList")),
  timezoneCallDuration: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/GroupCallDuration/GroupCallDuration")),
  aniBypass: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/AniByPass/AniByPass")),
  routingCriteria: lazy(()=> import("../../Phoenix/ChildComponents/CommonConfig/RoutingCriteria/RoutingCriteria")),
  zeon: lazy(()=> import("../../zeon/zeon")),
  orgFamily: lazy(()=> import("../../zeon/OrgFamily/OrgFamily")),
  exceptionRules : lazy(()=> import("../../zeon/ExceptionRules/ExceptionRules")),
  reports: lazy(()=> import("../../Reports/Reports")),
  users_profile: lazy(()=> import("../../Reports/UserProfile/UserProfile")),
  permissions: lazy(()=> import("../../AdminConsole/Permissions")),
  audit_report: lazy(()=> import("../../Reports/AuditReport/AuditReport")),
}

/**
 * Below is the code to create a tabs Display of react spectrum 
 * while leveraging the lazyloading features of react and 
 * loading from react spectrum
 * @param directList :array [] of objects related to the component
 */

export const TabsDisplay = ({directList, permissions, additioalPermissions={}, ...rest}) => {
// console.log("directList from tabsDisplay", directList);
// permissions = [...permissions , "AMAZON CONNECT","ALERT","ADMIN","SRS","USERS","SUPERVISORS","GROUP CONFIG","VOICE COMPLIANCE","ROUTING FLOW CONFIG","ATTRIBUTE LIST"]
console.log(" permissions from tabsDisplay ",permissions)
// console.log("rest from tabsDisplay", rest);

  return (
      <Tabs
          aria-label="Tabs"
          UNSAFE_style={{ paddingLeft: "1em" }}
      >
          <TabList>
            {directList?.map(tab=>(permissions?.includes(tab?.permission) && <Item key={tab.key}>{tab.title}</Item>))}
          </TabList>
          <TabPanels>
            {directList?.map(tab=>{
              const Component = Components[tab.key];
              return (permissions?.includes(tab.permission) && (<Item key={tab.key}>
                <ErrorBoundary>
                  <Suspense fallback={<ProgressCircle aria-label="Loading…" isIndeterminate />}>
                    <Component
                      currentComponent={tab.title}
                      directList={tab.children || []}
                      {...additioalPermissions}
                      {...rest}
                    />
                  </Suspense>
                </ErrorBoundary>
              </Item>))
            })}
          </TabPanels>
      </Tabs>
  )
}