import React from "react"
import { Flex, Grid, View, Heading } from "@adobe/react-spectrum"
// import DataProficiencyTable from "./DataProficiencyTable"
// import DataQueueTable from "./DataQueueTable"
import ComparisionTable from "./ComparisionTable"
import ArrowRight from "@spectrum-icons/workflow/ArrowRight"
import FailureReason from "./FailureReason"

export function DataComparison({ entry }) {

    return (
        <Flex direction="column" gap="size-300">
            <Grid
                areas={["oldData center newData"]}
                columns={["1fr", "size-800", "1fr"]}
                gap="size-300"
            >
                <View gridArea="oldData">
                    <ComparisionTable
                        data={entry?.oldData || []}
                        title="Previous Data"
                        variant="old"
                        isColumn={entry?.oldData?.length > 0}
                        columns={
                            (entry?.oldData?.length > 0 &&
                                Object.keys(entry?.oldData[0])) ||
                            []
                        }
                        type="small"
                    />
                </View>
                <Flex
                    gridArea="center"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Heading level={3} marginTop="15rem">
                        <ArrowRight />
                    </Heading>
                </Flex>
                <View gridArea="newData">
                    <ComparisionTable
                        data={entry?.newData || []}
                        title="New Data"
                        variant="new"
                        isColumn={entry?.newData?.length > 0}
                        columns={
                            (entry?.newData?.length > 0 &&
                                Object.keys(entry?.newData[0])) ||
                            []
                        }
                        type="small"
                    />
                </View>
            </Grid>

            <View marginTop="size-400">
                {entry?.diff?.added && (
                    <ComparisionTable
                        data={entry?.diff?.added || []}
                        title={"Added Data"}
                        variant={"added"}
                        status={false}
                        isColumn={entry?.diff?.added?.length > 0}
                        columns={
                            (entry?.diff?.added?.length > 0 &&
                                Object.keys(entry?.diff?.added[0])) ||
                            []
                        }
                        type="large"
                    />
                )}
                {entry?.diff?.removed && (
                    <ComparisionTable
                        data={entry?.diff?.removed || []}
                        title={"Removed Data"}
                        variant={"removed"}
                        status={false}
                        isColumn={entry?.diff?.removed?.length > 0}
                        columns={
                            (entry?.diff?.removed?.length > 0 &&
                                Object.keys(entry?.diff?.removed[0])) ||
                            []
                        }
                        type="large"
                    />
                )}

                {entry?.failureReason && (
                    <View UNSAFE_style={{ position: "absolute", bottom: 10 }}>
                        <FailureReason errorString={entry.failureReason} />
                    </View>
                )}
            </View>
        </Flex>
    )
}
